<template>
  <v-container class="my-12" style="padding-bottom: 160px">
    <v-sheet color="white" class="pa-10 rounded-lg shadow-sm">
      <text-component :id="28"></text-component>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-img src="https://examenselectivitat.cat:3000/api/articles/image/contacte-examenselectivitat-min.png"
               contain
               max-width="100%"
               max-height="200"
        ></v-img>
      </div>
    </v-sheet>

    <v-sheet color="white" class="pa-10 mt-6 rounded-lg shadow-sm">
      <h2 class="mb-4">Envia un missatge. Tots els camps són obligatoris.</h2>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            flat
            outlined
            color="#256EFF"
            type="text"
            background-color="#fdfdfd"
            label="Nom"
            v-model="name"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field
            flat
            outlined
            color="#256EFF"
            type="email"
            background-color="#fdfdfd"
            v-model="email"
            label="Correu electronic"z
          >
          </v-text-field>
        </v-col>
      </v-row>


      <v-text-field
        flat
        outlined
        color="#256EFF"
        type="text"
        class="mb-3"
        background-color="#fdfdfd"
        label="Assumpte"
        v-model="subject"
      >
      </v-text-field>
      <v-textarea
        v-model="message"
        flat
        outlined
        color="#256EFF"
        type="text"
        class="mb-2"
        background-color="#fdfdfd"
        label="Missatge"
      >
      </v-textarea>

      <p v-if="responseMsg">{{responseMsg}}</p>

      <div class="d-flex">
        <v-checkbox v-model="accept"
                    color="#256EFF"
                    label="He llegit i accepto l'avís legal i la política de privacitat."
                    class="mt-7"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn color="#256EFF"
               @click="enviarMissatge"
               :disabled="!accept || !name || !subject || !email || !message"
               depressed
               height="40"
               style="letter-spacing: 0.7px !important;"
               :loading="loading"
               class="text-none mt-6 px-10"
        >
          <span class="title white--text">Enviar</span>
        </v-btn>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
  import TextComponent from "../../components/TextComponent";

  export default {
    name: "Contacte",
    components: {TextComponent},
    data() {
      return {
        name: null,
        email: null,
        subject: null,
        message: null,
        accept: false,
        responseMsg: null,
        loading: false
      }
    },
    methods: {
      async enviarMissatge() {

        const body = {
          name: this.name,
          mail: this.email,
          subject: this.subject,
          message: this.message,
        }

        try {
          this.loading = true;
          const {data} = await this.axios.put('/messages', body);
          this.responseMsg = data.message;
          this.name = null;
          this.email = null;
          this.subject = null;
          this.message = null;
          this.accept = false;
        } finally {
          this.loading = false;
        }


      }
    }
  }
</script>

<style scoped>

</style>
